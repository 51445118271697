import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HamburgerService {
  private isHamburgerActiveSubject = new BehaviorSubject<boolean>(false);

  isHamburgerActive$ = this.isHamburgerActiveSubject.asObservable();

  setHamburgerActive(state: boolean): void {
    this.isHamburgerActiveSubject.next(state);
  }
}
