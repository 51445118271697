import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UserDetails } from 'src/app/shared/interfaces/user.interface';
import { HamburgerService } from '../../services/hamburger.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isHamburgerActive: boolean = false;
  userDetails: UserDetails | null = null;

  constructor(private localStorageService: LocalStorageService) {
   
   }

  ngOnInit(): void {
    this.initializeUser();
  }

  /**
   * Loads the user data from local storage and sets the `user` property.
   *
   * - Retrieves the user data from local storage as a JSON string.
   * - Parses the JSON string to a `User` object or sets `user` to `null` if no data exists.
   */
  private initializeUser() {
    this.userDetails = this.localStorageService.getItem('userDetails');
  }

  /**
   * Toggles the state of the hamburger menu between active and inactive.
   */
  toggleHamburger(): void {
    this.isHamburgerActive = !this.isHamburgerActive;
  }


  
}