export const MESSAGE = {
  UNAUTHORIZED_USER_ERROR: 'You are not an authorized user ',
  LOGIN_SUCCESS: 'Logged in Successfully',
  LOGIN_ERROR: 'Error:Please enter correct email id & password',
  BAD_GATEWAY: 'Error while Logging in Please try after sometime',
  NO_ROLE_ACCESS: "Currently you don't have access",
  PROFILE_NOT_FOUND: "Profile not Found",
  PLEASE_TRY_AGAIN: "Please try again after a while",
  SESSION_EXPIRED: 'Session expired. Please login again. Redirecting...',
  SESSION_CLEARED_TRY_AGAIN: 'Session expired, Please login again',
  SOMETHING_WENT_WRONG: 'Something went wrong , Please try again',
  BOQ_CREATION: 'BOQ created successfully',
  BOQ_CREATION_ERROR: 'Error: Incorrect data fields are highlighted below',
  BOQ_DRAFT: 'Draft saved successfully',
  BOQ_EDIT: 'Edit saved successfully',
  BOQ_AMEND:'Amend saved successfully',
  SELLING_PRICE_ERROR: 'Please contact marketing team',
  SELLING_PRICE_VALIDATION: 'Selling price cannot be empty or 0',
  DISCOUNT_ERROR: 'Discount percentage cannot exceed 100%',
  DISCOUNT__ERROR: 'Additional discount cannot be more than List price ',
  UPLOAD_FILE: 'File upload successfully',
  UPLOAD_FILE_ERROR: 'Invalid File',
  ITEM_ADDED: 'Item added successfully',
  FILL_CUSTOMER_DETAILS: 'Please fill in all the details correctly',
  NO_DATA_FOUND: 'No Data Found',
  NO_CUSTOMER_FOUND:'No Customer Found with the given details',
  NO_SALESPERSON_FOUND:'No Salesperson Found with the given details',
  FETCH_DATA: 'Fetching Data...',
  NO_WRITE_ACCESS:'You are not authorized, Kindly connect with Tech support !!',
  INPUT_CHECK:'Please check that all the details are filled in correctly',
  COMMENTS_REQUIRED:'Comment is required ',
  ACTION_PERFORM:'You cannot perform this action as it is pending on another user',
  NEGATIVE_SI_VALUE_CHECK:'SI price should be greater than 0, please enter valid Unit SP price.',
  ACTION_TAKEN:'cannot be taken on this BOQ',
  SUCCESS: {
    LOGIN_COMPLETED: 'Logged in successfully',
    UPLOAD_FILE: 'File uploaded successfully',
    DELIVERY_CHALLAN_GENERATED: 'Delivery Challan generated successfully.',
  },
  ERROR: {
    LOGIN_ERROR: 'Invalid email or password',
    UNAUTHORIZED_USER_ERROR: 'You are not authorized to access this',
    SESSION_EXPIRED: 'Session expired. Please log in again.',
    SESSION_CLEARED_TRY_AGAIN: 'Session cleared. Please log in again.',
    BAD_GATEWAY: 'Login error. Please try again later.',
    PLEASE_TRY_AGAIN: 'Please try again later.',
    SOMETHING_WENT_WRONG: 'Something went wrong. Please try again.',
    UPLOAD_FILE_ERROR: 'Invalid file. Please try again.',
    MODULE_ACCESS_DENIED: "You don't have access to this module. Contact support if you need help.",
  }
}